import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/redux/store.js";
import "./utils/i18n.js";
import { useState, useEffect } from "react";

import { Suspense, lazy } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import WhatsAppHotline from "./components/HotlineButton.js";

const Login = lazy(() => import("./pages/Login.js"));
const Verify = lazy(() => import("./pages/verify.js"));
const Register = lazy(() => import("./pages/Register.js"));
const SuccessfulRegister = lazy(() => import("./pages/SuccessfulRegister.js"));
const PersonalDetails = lazy(() => import("./pages/PersonalDetails.js"));
const AddAddress = lazy(() => import("./pages/AddAddress.js"));
const UpdateAddress = lazy(() => import("./pages/UpdateAddress.js"));
const Profile = lazy(() => import("./pages/Profile.js"));
const Rewards = lazy(() => import("./pages/Rewards.js"));
const Home = lazy(() => import("./pages/Home.js"));
const Loading = lazy(() => import("./pages/Loading.js"));
const Scan = lazy(() => import("./pages/Scan.js"));
const ScannedHistory = lazy(() => import("./pages/ScannedHistory.js"));
const Spin = lazy(() => import("./pages/Spin.js"));
const TNC = lazy(() => import("./pages/TNC.js"));
const FAQ = lazy(() => import("./pages/FAQ.js"));
const DeliveryAddress = lazy(() => import("./pages/DeliveryAddress.js"));
const Policy = lazy(() => import("./pages/Policy.js"));

function App() {
  const { t, i18n } = useTranslation();

  const [lang, setLang] = useState(localStorage.getItem("language") || "en");
  useEffect(() => {
    // Set the default language to 'en' when the component mounts
    i18n.changeLanguage(lang);
  }, [lang]); // Empty dependency array ensures that this effect runs only once on mount
  return (
    <div className="App">
      <WhatsAppHotline />{" "}
      <BrowserRouter>
        <Suspense>
          <Provider store={store}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/successfulRegister"
                element={<SuccessfulRegister />}
              />
              <Route path="/personalDetails" element={<PersonalDetails />} />
              <Route path="/addAddress" element={<AddAddress />} />
              <Route path="/updateAddress" element={<UpdateAddress />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/rewards" element={<Rewards />} />
              <Route path="/landing/:code?" element={<Loading />} />
              <Route path="/home" element={<Home />} />
              <Route path="/scan" element={<Scan />} />
              <Route path="/scanHistory" element={<ScannedHistory />} />
              <Route path="/spin" element={<Spin />} />
              <Route path="/tnc" element={<TNC />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/deliveryAddress" element={<DeliveryAddress />} />
              <Route path="/verify" element={<Verify />} />
              <Route path="/policy" element={<Policy />} />
              <Route path="*" element={<Navigate to="/landing" />} />
            </Routes>
          </Provider>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
